.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
/* Small screens */
@media (max-width: 768px) {
  .form-item {
    /* Example: Increase the font size for better readability on small screens */
    font-size: 18px;
  }
}

/* Large screens */
@media (min-width: 769px) {
  .form-item {
    /* Example: Decrease the font size for large screens */
    font-size: 16px;
  }
}
