@import "https://use.fontawesome.com/releases/v5.5.0/css/all.css";
html {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.login-box {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 500px;
  padding: 60px;
  transform: translate(-50%, -50%);
  background: #fdd1da;
  box-sizing: border-box;
  box-shadow: 0 2px 10px #d01f55;
  border-radius: 10px;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}

.login-box h2 {
  margin: 0 0 2em;
  padding: 0;
  color: #c90254;
  text-align: center;
}

.login-box .user-box {
  position: relative;
}

.login-box .user-box input {
  padding: 10px 0;
  font-size: 17px;
  color: #ffffff;
  margin-top: 1.5rem;
  margin-bottom: 28px;
  border-radius: 5px;
  border: none;
  border-bottom: 1px solid #ffffff;
  width: 100%;
  outline: none;
  background: transparent;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.login-box .user-box label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 0;
  font-size: 16px;
  color: #ffffff;
  pointer-events: none;
  transition: 0.5s;
}

.login-box .user-box input:focus ~ label,
.login-box .user-box input:valid ~ label {
  top: -20px;
  left: 0;
  color: #c90254;
  font-size: 1.1em;
  border-radius: 1em;
}

.login-box form a {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  color: #c90254;
  font-size: 18px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: 0.5s;
  margin-top: 40px;
  letter-spacing: 4px;
}

.login-box a:hover {
  background: #c90254;
  color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 0 5px #c90254, 0 0 15px #c90254, 0 0 50px #c90254,
    0 0 100px #c90254;
}

.login-box a span {
  position: absolute;
  display: block;
}

.login-box a span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #c90254);
  animation: btn-anim1 0.5s linear infinite;
}

@keyframes btn-anim1 {
  0% {
    left: -100%;
  }
  50%,
  100% {
    left: 100%;
  }
}

.login-box a span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #c90254);
  animation: btn-anim2 1s linear infinite;
  animation-delay: 0.25s;
}

@keyframes btn-anim2 {
  0% {
    top: -100%;
  }
  50%,
  100% {
    top: 100%;
  }
}

.login-box a span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #c90254);
  animation: btn-anim3 1s linear infinite;
  animation-delay: 0.5s;
}

@keyframes btn-anim3 {
  0% {
    right: -100%;
  }
  50%,
  100% {
    right: 100%;
  }
}

.login-box a span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #c90254);
  animation: btn-anim4 1s linear infinite;
  animation-delay: 0.75s;
}

@keyframes btn-anim4 {
  0% {
    bottom: -100%;
  }
  50%,
  100% {
    bottom: 100%;
  }
}

/* Modal button */
/* Change the background color of the OK button */
.ant-btn-primary {
  background-color: pink;
  border-color: pink;
}

/* Change the background color of the OK button on hover */
.ant-btn-primary:hover {
  background-color: black;
  border-color: black;
}
