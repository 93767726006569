.header {
  position: fixed;
  height: 90px;
  width: 100%;
  top: 1.7rem;
  left: 0;
  z-index: 1;
  transition: 0.3s ease-in;
  background-color: #95959500;
}
.header-bg {
  background-color: #9595955d;
}
/* header logo */
#logo {
  height: 4rem;
  width: 5rem;
}
.no-scroll {
  overflow: hidden;
}

.nav-menu.active {
  overflow-y: scroll; /* Add vertical scroll */
  height: 100vh; /* Set a fixed height */
}

/* footer logo */
#logo-two {
  height: 9rem;
  width: 10rem;
}

/* flag image */

#flag {
  height: 20px;
  width: 20px;
  margin-right: 5px;
}

.header .navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: auto;
  height: 100%;
  padding: 0 1rem;
}

.header .nav-menu a {
  color: #ffffff;
}

.header .navbar img {
  width: 250px;
  height: auto;
}

.header .nav-menu {
  display: flex;
}

.header .nav-item {
  padding: 1.5rem;
  font-weight: 400;
  font-size: 1rem;
}

.register-button {
  margin: 0.9rem;
  padding: 0.5rem 1rem;
  border: 2px solid #ce257a;
  color: #ce257a;
  border-radius: 2rem;
  width: 116px;
  height: 44px;
  background-color: transparent;
  -webkit-border-radius: 2rem;
  -moz-border-radius: 2rem;
  -ms-border-radius: 2rem;
  -o-border-radius: 2rem;
}

.register-button:hover {
  color: white;
  background-color: #ce257a;
  border-color: #ce257a;
}

/* 
.header .nav-item a:hover {
  padding-bottom: 12px;
  border-bottom: 3px solid green;
} */

.hamburger {
  display: none;
}

@media screen and (max-width: 940px) {
  .header {
    max-width: 100%;
    background-color: #9595955d;
  }

  .header .navbar {
    max-width: 100%;
  }

  .hamburger {
    display: block;
  }

  .nav-menu {
    position: fixed;
    left: -100%;
    top: 90px;
    flex-direction: column;
    background-color: rgb(19, 22, 26);
    width: 100%;
    height: 90vh;
    z-index: 999;
    text-align: center;
    transition: 0.3s;
  }

  .nav-menu.active {
    /* left: 0;
    align-items: center; */
    position: fixed;
    left: 0;
    top: 0;
    flex-direction: column;
    background-color: rgb(19, 22, 26);
    width: 100%;
    height: 100vh; /* Full screen */
    z-index: 999;
    align-items: center;
    padding-top: 20px; /* Space for the logo */
  }

  .nav-item {
    margin: 1rem 0;
  }

  .header .navbar img {
    width: 150px;
  }
  .nav-menu .nav-item {
    padding: 5px 1.5rem; /* Adjust as needed */
    font-size: 1rem;
    font-weight: 800;
  }
  .nav-item div {
    justify-content: space-around; /* Add space between social media icons */
  }
  #MenuLogo {
    height: 6.3rem;
    width: 7rem;
  }
}
