/* footer font */
@import url("https://fonts.googleapis.com/css2?family=Sora:wght@400;600&display=swap");

@layer components {
  .sora {
    @apply font-sora;
  }
}
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* Add any custom styles here */

html,
body,
#root,
.app {
  height: 100vh;
  /* width: 98vw; */
  font-family: "Sora", sans-serif;
}

.app {
  display: flex;
  flex-direction: column;
}

.footer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 4px 20px 4px 12px;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.content {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: "auto";
}

.sidebar {
  height: 100%;
}

.sidebarVertical {
  height: 100%;
}

.pagecontent {
  padding-left: 20px;
}

/* Default styles for the header */

/* settingpage css */
.input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 4px;
}

.btn {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.range {
  width: 100%;
}

/* menu */

.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}
.Header {
  position: fixed;
  width: 100%;
  z-index: 1;
}

.Footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 1;
}

/* table*/

@media screen and (max-width: 768px) {
  .responsive-table {
    overflow-x: auto;
  }
}

@media screen and (max-width: 576px) {
  .responsive-table .ant-table-thead th {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
