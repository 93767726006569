.select-style {
  color: #ce257a;

  width: 10%;
  text-align: left;
  border-color: #ce257a;
  border-width: 2px;
}

@media (max-width: 768px) {
  .select-style {
    width: 60%;
    margin-right: 10px;
    margin-bottom: 10px;
  }
}
