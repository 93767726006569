#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}

.ant-layout-content .content {
  margin: 24px 16px;
  padding: 24px;
  min-height: 280px;
}

/* .image-collapsed {
  float: left;
  width: 40px;
  margin: 16px 16px 16px 16px;
  padding-left: 20px;
}

.image-uncollapsed {
  float: left;
  width: 100px;
  margin: 16px 16px 16px 16px;
  padding-left: 20px;
} */
/* for sider */
.image-collapsed {
  display: none;
}

.image-uncollapsed {
  width: 100%; /* adjust as needed */
  height: auto; /* adjust as needed */
}

/* for modal */
.myCustomModal .ant-modal-confirm-title,
.myCustomModal .ant-modal-confirm-content {
  color: #ce257a;
  font-family: "Sora", sans-serif;
}

.myCustomModal .ant-btn-primary {
  background-color: #ce257a;
  border-color: #ce257a;
  color: white;
}

.myCustomModal .ant-btn-primary:hover,
.myCustomModal .ant-btn-primary:focus {
  background-color: #ce257a;
  border-color: #ce257a;
  color: white;
}
/* Dropdown options */
.myCustomModal .ant-modal-confirm-body .anticon-exclamation-circle {
  color: #ce257a;
}

.myCustomMenuItem {
  font-family: "Sora", sans-serif;
}
.myCustomMenuItem:hover {
  background-color: #ce257a;
  color: white;
}
/* Notification */
.myCustomDropdown {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  width: 300px;
  box-sizing: border-box;
  padding: 10px;
}
/*  Notification Font styling */
.myCustomTitle,
.myCustomLink,
.myCustomNoData,
.myCustomMessage {
  font-family: "Sora", sans-serif;
}

.myCustomTitle {
  font-size: 20px;
}

.myCustomNoData,
.myCustomMessage {
  text-align: center;
}

.myCustomDropdown {
}

@media (max-width: 750px) {
  .myCustomDropdown {
    width: 10%;
  }
}
/* Font Styling for submenu */
.ant-menu-item,
.ant-menu-item-group-title {
  font-family: "Sora", sans-serif;
  font-size: 13px;
}
/* Font Styling for menu */
.ant-menu-submenu-title {
  font-family: "Sora", sans-serif;
  font-size: 13px;
}
