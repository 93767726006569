@tailwind base;
@tailwind components;
@tailwind utilities;

/* footer font */
@import url("https://fonts.googleapis.com/css2?family=Sora:wght@400;600&display=swap");

@layer components {
  .sora {
    @apply font-sora;
  }
}
/* Font for client form */
.sora-form .ant-form-item-label > label {
  font-family: "Sora", sans-serif;
}
.sora-form input::-webkit-input-placeholder {
  font-family: "Sora", sans-serif;
}

.sora-form input::-moz-placeholder {
  font-family: "Sora", sans-serif;
}

.sora-form input:-ms-input-placeholder {
  font-family: "Sora", sans-serif;
}

body {
  background-color: #ce257a !important;
  /* font-family: "Nunito Sans", sans-serif; */
  font-family: "Sora", Sans-serif;
  width: 100%;
}

.hover-effect:hover {
  box-shadow: 0 0 5px 1px #ce257a;
}

.pink-button {
  background-color: #ce257a;
  color: white;
}

.pink-button:hover {
  background-color: #8f1954 !important;
  color: white !important;
}

.custom-underline {
  position: relative;
}

.custom-underline::after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 100%;
  height: 0.1rem;
  background-color: #ce257a; /* Replace with your custom color */
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.custom-underline:hover::after,
.custom-underline:active::after {
  opacity: 1;
}

/* Modal Button */
.modalButton {
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  padding: 12px 24px;
}

/* Modal */
.overlay {
  /* background-color: rgba(0, 0, 0, 0.5); */
  position: fixed;
  width: 100%;
  height: 100%;
}

.modalContainer {
  max-width: 600px;
  width: 100%;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  background-color: #ffffff;
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.75);
  border-radius: 8px;
}

img {
  width: 250px;
  object-fit: cover;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.modalRight {
  width: 100%;
}

.closeBtn {
  position: fixed;
  top: 8px;
  right: 8px;
  height: 30px;
  width: 30px;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-top: 3rem;
  padding: 1rem 2rem;
}

.btnContainer {
  display: flex;
  padding: 1rem 1rem;
}
.btnContainer button {
  width: 100%;
  margin: 0.5rem;
  padding: 16px 0;
  /* border: none; */
  border: 1px solid #ce257a;
  /* color: #ffffff; */
}

.btnPrimary {
  background-color: #ce257a;
  color: white;
}

.btnOutline {
  background-color: white;
  color: #ce257a;
}

.bold {
  font-weight: 600;
}

@media screen and (max-width: 500px) {
  .modalContainer {
    flex-direction: column;
    top: 0;
    left: 0;
    transform: none;
    width: 100%;
    height: 100vh;
  }
  img {
    width: 100%;
    max-height: 70vh;
    object-fit: cover;
  }

  .heading {
    margin: 1rem;
  }
}

/* Custom Pagination Styles */
.custom-pagination .ant-pagination-item-active,
.custom-pagination .ant-pagination-item-active a,
.custom-pagination .ant-pagination-item-active:focus,
.custom-pagination .ant-pagination-item-active:hover {
  background-color: #ce257a;
  border-color: #ce257a;
  color: white;
}

.custom-pagination .ant-pagination-item:focus,
.custom-pagination .ant-pagination-item:hover,
.custom-pagination .ant-pagination-jump-prev:focus,
.custom-pagination .ant-pagination-jump-prev:hover,
.custom-pagination .ant-pagination-jump-next:focus,
.custom-pagination .ant-pagination-jump-next:hover {
  border-color: #ce257a;
  color: #ce257a;
}

.custom-pagination .ant-pagination-options-quick-jumper input:focus,
.custom-pagination .ant-pagination-options-quick-jumper input:hover {
  border-color: #ce257a;
}

/* Overlay for other services gird system */

.overlay {
  position: relative;
}

.overlay::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.overlay:hover::before {
  opacity: 1;
}

.overlay-content {
  position: relative;
  z-index: 1;
}

/* Details Page */

.parent-div {
  background-color: #ce257a;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  padding: 2rem;
}

.child-div {
  background-color: white;
  padding: 2rem;
  /* backdrop-filter: blur(10px); */
  opacity: 0.9;
  transition: all 0.3s ease-in-out;
}

.child-div:hover {
  opacity: 1;
  transform: scale(1.02);
}

/* To adjust date and time preferences for responsiveness */

@media (max-width: 767px) {
  .text-preference {
    display: flex;
    flex-wrap: wrap;
  }

  .text-preference span {
    flex-basis: 100%; /* each span takes full width */
  }
}

/* Font Size for details pages */

.fontx {
  font-size: 18px;
}

/* 
Footer links hover and active effect */
.footer-item a {
  transition: color 0.3s ease;
  color: white;
}

.footer-item a:hover,
.footer-item a:active {
  color: black;
}

/* Terms and Conditions */
a.terms {
  color: #ce257a;
}

a.terms:hover {
  color: #6f1e94;
}

input[type="checkbox"].pinkCheckbox:checked {
  background-color: pink;
}

/* Bounce effect for contact Image */
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.animate-bounce {
  animation: bounce 1s ease infinite;
}
