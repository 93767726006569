/*Overriding the layout which made the view small on mobile*/
/*Set padding of left and right to be 4% (percentage is used to be responsive)*/
.ant-layout-content {
  padding: 0 4% !important;
}
/* For desktop screens */
.custom-item label {
  display: block;
  background-color: brown;
}

/* For mobile screens */
@media screen and (max-width: 768px) {
  .custom-item label {
    display: inline-block;
    background-color: brown;
  }
}

.ant-descriptions-item-content {
  font-family: "Sora", sans-serif;
}
