.star-loader {
  position: relative;
  width: 50px;
  height: 50px;
  display: inline-block;
  animation: star-loader 1s infinite linear;
  transform-origin: 50% 50%;
}

.star-loader div {
  position: absolute;
  width: 10px;
  height: 10px;
  background: #ce257a;
}

.star-loader div:nth-child(1) {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.star-loader div:nth-child(2) {
  top: 0;
  left: 0;
  animation: star-loader-part 1s infinite ease-in-out;
}

.star-loader div:nth-child(3) {
  top: 0;
  right: 0;
  animation: star-loader-part 1s infinite ease-in-out;
}

.star-loader div:nth-child(4) {
  bottom: 0;
  left: 0;
  animation: star-loader-part 1s infinite ease-in-out;
}

.star-loader div:nth-child(5) {
  bottom: 0;
  right: 0;
  animation: star-loader-part 1s infinite ease-in-out;
}

@keyframes star-loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes star-loader-part {
  0%,
  100% {
    width: 10px;
    height: 10px;
  }
  50% {
    width: 25px;
    height: 25px;
  }
}
