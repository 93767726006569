.editable-row .ant-form-item-explain {
  position: absolute;
  top: 100%;
  font-size: 12px;
}
/* Add this to your global CSS file */
.ant-popover-buttons .ant-btn-primary {
  background-color: #ce257a !important;
  border-color: #ce257a !important;
  color: white !important;
}

.ant-popover-buttons .ant-btn-primary:hover {
  background-color: brown !important;
  border-color: brown !important;
}
/* Add this to your global CSS file */
.ant-pagination-item-active {
  background-color: #ce257a !important;
  border-color: #ce257a !important;
  color: white !important;
}

.ant-pagination-item-active a {
  color: white !important;
}

.ant-pagination-item-active:hover {
  background-color: brown !important;
  border-color: brown !important;
}
.scrollable-table {
  overflow-x: auto;
}
