.loader {
  position: relative;
  margin: 0 auto;
  width: 100px;
  height: 100px;
}

.face {
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #ff4081;
  animation: spin 2s linear infinite;
}

.circle,
.eye {
  position: absolute;
  background-color: #fff;
  border-radius: 50%;
}

.circle {
  top: 30%;
  left: 25%;
  width: 50%;
  height: 50%;
}

.eye {
  top: 35%;
  width: 15%;
  height: 15%;
}

.eye.left {
  left: 20%;
}

.eye.right {
  right: 20%;
}

.shadow {
  position: absolute;
  bottom: 5%;
  left: 10%;
  width: 80%;
  height: 10%;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  animation: shadow 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes shadow {
  0% {
    transform: scaleX(1.5);
  }
  50% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(1.5);
  }
}
